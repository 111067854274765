.tag {
  display: inline-flex;
  border: 1px solid var(--slate-6);
  background: var(--slate-1);
  padding: var(--space-1) var(--space-2);
  align-items: center;
  border-radius: var(--radius-2);

  .label {
    color: var(--slate-11);
    white-space: nowrap;
  }

  &.isInteractive {
    cursor: pointer;

    &:hover {
      background: var(--slate-2);
    }
  }

  .closeButtonContainer {
    border-left: 1px solid var(--slate-6);
    margin-left: var(--space-2);
  }

  .closeButton {
    display: flex;
    border-radius: var(--radius-2);
    padding: var(--space-1);
    cursor: pointer;
    margin-left: var(--space-2);
    border: none;
    background: none;

    &:hover {
      background: var(--slate-4);
    }

    .closeButtonIcon {
      width: var(--space-3);
      height: var(--space-3);
    }

    svg path {
      stroke: var(--slate-9);
    }
  }

  .startIcon {
    width: var(--space-4);
    height: var(--space-4);
    margin-right: var(--space-2);
    color: var(--slate-9);
  }

  .endIcon {
    margin-left: var(--space-2);
    width: var(--space-4);
    height: var(--space-4);
    color: var(--slate-9);
  }
}
