.commandMenuItem {
  content-visibility: auto;
  cursor: pointer;
  height: var(--space-8);
  border-radius: var(--space-2);
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: var(--space-2);
  padding: 0 var(--space-4);
  color: gray;
  user-select: none;
  will-change: background, color;
  transition: all 150ms ease;
  transition-property: none;

  &[data-selected="true"] {
    background: var(--slate-3);
    color: var(--indigo-10);
  }

  &[data-disabled="true"] {
    color: var(--slate-8);
    cursor: not-allowed;
  }

  &:active {
    transition-property: background;
    background: var(--slate-5);
  }

  & + [cmdk-item] {
    margin-top: var(--space-1);
  }

  .itemIcon {
    width: var(--space-4);
    height: var(--space-4);
  }

  .itemCheckbox {
    pointer-events: none;
  }

  .count {
    margin-left: auto;
  }

  .kbdContainer {
    margin-left: auto;
  }

  .kbd {
    vertical-align: baseline;
    text-align: center;
    line-height: 110%;
    font-feature-settings: "ss02";
    background-color: var(--slate-3);

    background: var(--slate-4);
    color: var(--slate-10);
    font-size: 12px;
    border-radius: var(--radius-2);
    padding: var(--space-1);
    min-width: var(--space-5);
    height: var(--space-5);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: var(--font-sans);
    margin-right: var(--space-1);

    &:last-child {
      margin-right: 0;
    }
  }
}
