.createFeedBtnContainer {
  border: 1px dashed var(--slate-6);
  border-radius: var(--radius-2);
}

.emojiBox {
  background-color: var(--slate-4);
  border-radius: var(--radius-2);
  text-align: center;
  width: var(--space-7);
  height: var(--space-7);
}
