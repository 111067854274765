.profilePageContainer {
  width: 100%;
}

.halfColumn {
  width: 50%;
}

.messageBtn {
  width: fit-content;
}

.threadsBtn {
  width: fit-content;
}

.profilePic {
  width: 160px;
  height: 160px;
}
