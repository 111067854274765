@font-face {
  font-family: "Noto Emoji";
  src: url("/fonts/NotoEmoji.woff2") format("woff2");
  font-style: medium;
  font-weight: normal;
  font-display: swap;
}

.notoEmoji {
  font-family: "Noto Emoji", sans-serif;
}
