.loader {
  border: 3px solid var(--slate-4);
  border-bottom-color: var(--indigo-8);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.default {
  position: absolute;
  height: var(--space-5);
  width: var(--space-5);
  right: calc(var(--space-9) + var(--space-8));
  top: calc(50% - var(--space-3));
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
