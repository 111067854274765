.keyboardShortcut {
  vertical-align: baseline;
  text-align: center;
  border-radius: var(--radius-2);
  padding: var(--space-1);
  min-width: var(--space-2);
  height: var(--space-2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  background: var(--slate-3);
  border: 1px solid var(--slate-5);

  .startIcon {
    height: var(--space-3);
    width: var(--space-3);
    margin-right: var(--space-1);
    color: var(--slate-9);
  }
}
