.userBlock {
  width: 100%;
  border: 1px solid var(--slate-6);
  border-top-left-radius: var(--radius-4);
  border-top-right-radius: var(--radius-4);
  border-bottom: none;
  cursor: default;
  height: var(--space-6);

  &:hover {
    background-color: var(--slate-5);
  }
}
