.wsStatusDot {
  width: var(--space-2);
  height: var(--space-2);
  border-radius: 50%;
  animation: animate-indicator;
  animation-duration: 10s;
  animation-fill-mode: forwards;
}
.wsStatusLabel::after {
  animation: animate-text;
  animation-fill-mode: forwards;
  // animation-timing-function: ease-in;

  animation-duration: 10s;
  content: "Connected";
}

@keyframes animate-text {
  0% {
    color: var(--green-11);
    content: "Connected";
  }
  40% {
    color: var(--green-11);
    content: "Connected";
  }

  60% {
    color: var(--green-11);
    content: "Connected";
  }
  90% {
    color: var(--slate-a8);
    content: "Connected";
  }

  100% {
    color: var(--slate-a8);
    content: "Disconnected";
  }
}

@keyframes animate-indicator {
  0% {
    background: var(--green-9);
  }
  40% {
    background: var(--green-9);
  }

  60% {
    background: var(--green-a3);
  }
  90% {
    background: var(--slate-a3);
  }

  100% {
    background: var(--slate-a3);
  }
}
