.orgItem {
  padding: var(--space-2);
  border-radius: var(--radius-3);
  cursor: default;

  .icon {
    opacity: 0;
  }

  &:hover {
    background-color: var(--slate-3);
    .icon {
      opacity: 1;
    }
  }
}
