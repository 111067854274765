.profilePicturePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;

  .imagePreview {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: var(--radius-4);
  }

  .imagePreviewContent {
    position: relative;
    border-radius: var(--radius-4);
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(calc(var(--space-1) * -1));
    }
  }
}
