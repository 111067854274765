.dialog {
  --cmdk-width: 640px;
  --cmdk-transform: translateX(-50%);
  z-index: 99999;
  position: fixed;
  top: 20%;
  left: 50%;
  background: var(--slate-1);
  border-radius: var(--radius-4);
  width: 100%;
  max-width: var(--cmdk-width);
  transform-origin: 50%;
  outline: 0;
  opacity: 0;
  transform: var(--cmdk-transform);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  transition-property: opacity, transform;

  [data-state="open"] & {
    opacity: 1;
  }

  .group {
    [cmdk-group-heading] {
      position: sticky;
      top: 0;
      color: var(--slate-11);
      display: flex;
      align-items: center;
      z-index: 9999;

      user-select: none;
      font-size: var(--font-size-1);
      padding: 0 var(--space-2);
      margin-bottom: var(--space-2);
    }
  }

  .input {
    padding: var(--space-5);
    padding-right: 192px;
    grid-area: 1 / 1 / auto / auto;
    margin: 0;
    border: none;
    appearance: none;
    font-size: inherit;
    height: var(--space-4);
    background: transparent;
    color: var(--slate-12);
    outline: none;
    width: 100%;
    border-bottom: 1px solid var(--slate-6);

    &::placeholder {
      color: var(--slate-8);
    }
  }

  .shortcutContainer {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: var(--space-4);
    opacity: 0.5;
    user-select: none;

    .shortcut {
      margin-right: var(--space-2);
    }

    .label {
      color: var(--olive-9);
    }
  }

  .spinner {
    position: absolute;
    height: var(--space-5);
    width: var(--space-5);
    right: calc(var(--space-9) + var(--space-8));
    top: calc(50% - var(--space-3));
  }

  .list {
    height: calc(var(--space-1) + var(--cmdk-list-height));
    max-height: 384px; // var(--space-96);
    will-change: height;

    padding: var(--space-2);
    overflow: auto;
    overscroll-behavior: contain;

    scroll-padding-block-start: var(--space-2);
    scroll-padding-block-end: var(--space-2);

    &.emptyList {
      padding: 0;
      margin-top: 0;
      height: 0;
    }
  }

  .tags {
    display: flex;
    align-items: center;

    padding: var(--space-2) var(--space-2) var(--space-4) var(--space-2);
    border-bottom: 1px solid var(--olive-6);
    margin-bottom: var(--space-2);
  }

  .empty {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--space-9);
    white-space: pre-wrap;
    text-align: center;
  }

  // TODO: Adapt to the design system
  .threadItem {
    display: flex;
    flex-direction: column;
    padding: var(--space-3);
    border-radius: var(--radius-4);

    .title {
      font-weight: var(--font-weight-medium);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      mark {
        color: var(--indigo-9);
        background-color: transparent;
      }
    }

    .description {
      font-size: var(--font-size-s);
      line-height: var(--line-height-s);
      color: var(--olive-8);
      overflow: hidden;
      display: -webkit-box;
      // -webkit-line-clamp: 2; /* number of lines to show */
      // line-clamp: 2;
      // -webkit-box-orient: vertical;

      strong {
        font-weight: var(--font-weight);
        color: var(--color-font-light);
      }

      mark {
        color: var(--olive-8);
        // text-decoration: underline;
        // text-decoration-color: var(--indigo-9);
        background-color: transparent;
      }
    }

    &[data-selected="true"] {
      background: var(--olive-3);
      &.mentionPanelThreadItem {
        background: var(--purple-a3);

        .title {
          color: var(--purple-9);
        }
      }

      .title {
        color: var(--indigo-9);
      }
    }

    &[data-disabled="true"] {
      color: gray;
      cursor: not-allowed;
    }

    &:active {
      transition-property: background;
      background: var(--slate-a4);
    }

    & + [cmdk-item] {
      margin-top: var(--space-1);
    }

    svg {
      width: var(--space-4);
      height: var(--space-4);
    }
  }
}
