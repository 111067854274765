@import "@/app/styles/mixings";

.sidebar {
  width: 100%;
  position: absolute;
  z-index: 556;
  border-right: 1px solid var(--slate-4);
  background: var(--slate-2);
  box-shadow:
    inset -1px 0 0 1px var(--slate-a1),
    inset -4px 0 2px -2px var(--slate-a1);
  height: 100%;
  display: flex;
  flex-direction: column;

  transform: translateX(-100%);
  transition: none;

  &.open {
    transform: translateX(0%);
    transition: transform 0.15s ease-in-out;
  }

  @include respond-above(sm) {
    width: 216px;
    position: initial;
    transform: none;
  }

  .menuButtonContainer {
    white-space: nowrap;
    flex: 1;
    max-width: 100%;
    width: 100%;

    .menuButton {
      overflow: hidden;
      gap: var(--space-2);
    }

    &:hover .menuButton {
      background: var(--accent-a2);
    }

    &:hover .menuButtonPopoverTrigger {
      // visibility: visible;
      display: flex;
    }
  }

  .menuButtonPopoverTrigger {
    display: none;
    // visibility: hidden;
    box-shadow: none;

    &[data-state="open"] {
      display: flex;
      // visibility: visible;
    }
  }

  .menuLink {
    display: flex;
    height: var(--space-6);
    padding: 0 var(--space-3);
    gap: var(--space-2);
    align-items: center;
    text-decoration: none;
    color: var(--accent-12);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-2);
    border-radius: var(--radius-3);
    &:hover {
      background: var(--accent-a2);
    }
  }
}
