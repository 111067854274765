html,
body,
#app,
#root,
#__next {
  height: 100%;
  width: 100%;
}

body > .radix-themes {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.radix-themes {
  --default-font-family: var(--font-inter);
  //--font-weight-light: 200;
  --font-weight-regular: 450;
  --font-weight-medium: 540;
  --font-weight-bold: 625;
}

:root,
.light,
.light-theme {
  --white-1: #ffffff;
  --black-1: #000000;
}

.dark,
.dark-theme {
  --white-1: #000000;
  --black-1: #ffffff;
}
